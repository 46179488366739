*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.order {
    display: flex;
    background-color: rgb(233, 233, 233);
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    justify-content: space-between;
    margin-bottom: 1em;
}

.order .inner-div {
    display: inline-flex;
  
  
}

.order .details .small{
    margin-bottom: 5em;
  
}

.order .details .small h2{
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 1em;
}
.order .inner-div img {
    margin-right: 2em;
}