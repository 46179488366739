.head {
    display: flex;
    align-items: center;
    /* padding-top: 10px; */
    /* padding-left: 40px; */
    padding-bottom: 0;
}

.hold-search {
    width: 725px;
    height: 67px;
    margin-top: 20px;
}

.flag {
    height: 26.43px;
    width: 37px;
    border-radius: 2px;
}

.flag-container {
    width: 59px;
    height: 27px;
}

.cart-icon {
    width: 25px;
    height: 23px;
}

.larger-holder {
    width: 600px;
}

.search-pre-sqr-border-left {
    border-radius: 10px 0 0 10px;
}

.search-pre-sqr-border-right {
    border-radius: 0 10px 10px 0;
}

.search-pre-sqr {
    width: 55px;
    height: 57px;
    line-height: 57px;
    font-weight: 1000;
    text-align: center;
    margin: auto;
}

.flag.dropdown-toggle:empty::after {
    margin-left: 40px;
    margin-top: 19px;
}

.font-round-b-bottom {
    border-bottom-color: #212529;
    border-bottom-width: 1.5px;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
}

@media (min-width: 1200px) {
    .category.container {
        max-width: 100vw;
    }
}

@media (min-width: 768px) {
    #hold_search {
        display: none;
    }
    nav {
        background-color: #28a745;
    }
    .towhite {
        color: #fafafa;
    }
    #towhite {
        color: #fafafa
    }
    #towhite1 {
        color: #fafafa
    }
    #towhite2 {
        color: #fafafa
    }
    #towhite3 {
        color: #fafafa
    }
    .small_screen_header {
        display: none;
    }
    #head-logo {
        height: 59.74px;
        width: 128.75px;
        margin-right: 30.25px;
    }
}

@media (max-width: 767px) {
    nav .navbar-toggler-icon {
        color: #000
    }
    #hold_search {
        margin-top: 10px;
    }
    .head {
        display: none;
    }
    .cart-text {
        display: none;
    }
    .logtext {
        display: none;
    }
    #head-logo {
        height: 31px;
        width: 66px;
    }
}

.category-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none !important;
}

.category-text:hover,
.cardx a {
    text-decoration: none !important;
}

.dropdown-link,
.dropdown-link:hover {
    color: black;
    display: block;
    text-align: center;
}

.dropdown-link.btn.tw-bg-adehot,
.dropdown-link.btn.tw-bg-adehot:hover {
    color: white;
}