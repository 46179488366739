.cart-card {
    background-color: #F7F7F7;
    padding: 44px 16px;
    position: relative;
    border-radius: 13px;
}

.btn:disabled {
    cursor: not-allowed;
}

.description h3 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 1em;
    font-family: inter;
}

.description p {
    margin: 1em 0;
}

.price-col small {
    margin-top: 0.5em
}

.icons span {
    display: inline-flex;
}

.icons span:nth-child(2) {
    margin-left: 2em;
}

.lg-show {}

.sm-show {
    display: none;
}

.btn-default {
    background-color: rgb(223, 222, 222) !important;
}

.quantity {
    display: inline-flex;
}

@media screen and (max-width: 670px) {
    .lg-show {
        display: none;
    }
    .sm-show {
        display: block;
        margin: 1em 0;
    }
}