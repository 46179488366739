@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
.text-9xl {
    font-size: 14rem;
}

@media (max-width: 645px) {
    .text-9xl {
        font-size: 5.75rem;
    }
    .text-6xl {
        font-size: 1.75rem;
    }
    .text-2xl {
        font-size: 1rem;
        line-height: 1.2rem;
    }
    .py-8 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .px-6 {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    .mr-6 {
        margin-right: 0.5rem;
    }
    .px-12 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.bg-adehot {
    background-color: #5CC10F;
    color: #fafafa;
}

.text-adehot {
    color: #5CC10F;
}

.shadow {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease-in;
}

.text-product {
    font-family: Inter;
    font-style: normal;
}

.text-product-price {
    font-weight: 500;
    /* font-size: 20px;
    line-height: 24px; */
}

.text-product-name {
    font-weight: 600;
    /* font-size: 30px;
    line-height: 36px; */
}

a:hover {
    text-decoration: none;
}

a.link-btn:hover {
    color: inherit;
}

.product-images-container {
    height: auto;
    width: 108px;
}

.checkout-item-box::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    cursor: pointer;
}

.checkout-item-box::-webkit-scrollbar-track {
    /* background-color: rgba(229, 231, 235, var(--bg-opacity)); */
    cursor: pointer;
    /* background-color: #9ac6ad; */
    background-color: #d6e2dc;
    /*background: red;*/
}

.checkout-item-box::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #9ac6ad;
    /*outline: 1px solid slategrey;*/
}