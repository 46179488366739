@media (min-width:760px) {
    .fhead {
        font-size: 0.7em;
    }
    .card {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #f6f6f6;
    }
    .card_header {
        /* height: 27px; */
        width: 90%;
    }
    .card_body {
        height: 320px;
        width: 90%;
        border-radius: 0px;
    }
    .card_footer {
        height: 27px;
        width: 90%;
        color: black
    }
    footer {
        height: fit-content;
        width: 100%;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background-color: #F1F1F1;
        margin-top: 130px;
        padding-top: 50px;
    }
    #footer_logo {
        height: 60px;
        width: fit-content;
        border-radius: 0px;
    }
    .footer_col {
        width: 100%;
        padding-left: 0px;
    }
    .footer_header_text {
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        height: 31px;
        margin-bottom: 48px;
    }
    .footer_list {
        list-style-type: none;
        font-family: Poppins;
        font-size: 16.8px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 0;
    }
    .footer_list li {
        margin-top: 33px;
    }
    .footer_list li a {
        color: black
    }
    .icon_holder {
        width: fit-content;
        height: 60px;
        margin-top: 100px;
        float: right;
        margin-left: 279px;
        display: flex;
    }
    .icon_holder .icon {
        height: 42.99998092651367px;
        width: 43px;
        margin: 20px;
        border-radius: 0px;
    }
}

@media (max-width:380px) {
    h6.cart-text {
        display: none;
    }
    .log-text-down {
        font-size: 12px;
    }
}

@media (max-width:575px) {
    .card {
        /* height: auto; */
        width: 100% !important;
    }
    .card_header h5,
    .card_footer a {
        /* font-size: 20px; */
        text-overflow: ellipsis;
    }
}

@media (max-width:760px) {
    .fhead {
        font-size: 0.6em;
    }
    .card {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #F9F9F9;
    }
    .card_header {
        /* height: 30px; */
        width: 90%;
    }
    .card_body {
        height: 110px;
        width: 90%;
        border-radius: 0px;
    }
    .card_footer {
        height: 20px;
        width: 90%;
        color: black
    }
    footer {
        height: fit-content;
        width: 100%;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background-color: #F1F1F1;
        margin-top: 130px;
        padding-top: 50px;
    }
    #footer_nav {
        padding-left: 20px;
    }
    #footer_logo {
        height: 60px;
        width: fit-content;
        border-radius: 0px;
    }
    .footer_col {
        width: 100%;
        padding-left: 0px;
    }
    .footer_header_text {
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        height: 31px;
        margin-bottom: 10px;
        margin-top: 30px;
    }
    .footer_list {
        list-style-type: none;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 0;
    }
    .footer_list li {
        margin-top: 33px;
    }
    .footer_list li a {
        color: #000
    }
    .icon_holder {
        width: fit-content;
        height: 60px;
        margin-top: 40px;
        float: right;
        display: flex;
    }
    .icon_holder .icon {
        height: 42.99998092651367px;
        width: 43px;
        margin: 20px;
        border-radius: 0px;
    }
}