.modal-parent {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.842);
    z-index: 1;
    height: 100vh;
    width: 100vw;
}

.collabs {
    color: rgb(10, 4, 4);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 2em;
    margin-left: 8em;
}


/* styling for the child modal */

.modalbox {
    width: 50%;
    background-color: rgb(255, 254, 254);
    max-width: 70%;
    border-radius: 2em;
    padding: 2em 2em;
    margin: auto;
    margin-top: 10em;
}

.modalbox form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 20em;
}

.modalbox form input {
    width: 100%;
    padding: 1em;
    margin-bottom: 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    font-size: 1em;
}

.modalbox form button {
    width: auto;
    padding: 1em;
    margin-top: 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    font-size: 1em;
    cursor: pointer;
}


/*style for mobile phone*/

@media only screen and (max-width: 600px) {
    .modalbox {
        width: 100%;
        max-width: 80%;
    }
}