/* import the styles */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:wght@200;300&family=Poppins:wght@200;300;400;500;600&display=swap');
* {
    font-family: var(--poppins);
}

:root {
    --inter: iner;
    --poppins: poppins;
    --mont: montserrat;
}

@media (min-width: 760px) {
    .cardx {
        width: 110px;
        height: 90px;
    }
    .cat-img {
        width: 60px;
        height: 50px;
    }
    .bg-holder {
        position: relative;
        width: 100%;
        height: fit-content;
        background-color: #ccc;
    }
    .swiper {
        width: 100%;
        height: 480px;
        z-index: 3;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 480px;
        object-fit: fill;
    }
    #sectioner {
        position: absolute;
        left: 0;
        top: 305px;
        z-index: 30;
        width: 100%;
    }
    #sectioner #secondcat {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }
    .slide {
        height: 394px;
        width: 95vw;
        left: 40px;
        top: 1719px;
        border-radius: 0px;
        background-color: #fdfdfd;
        padding-left: 84px;
        padding-right: 100px;
        padding-top: 30px;
    }
    #apex {
        margin-top: 125vh;
    }
    .slide_img {
        height: 161.29721069335938px;
        width: 203px;
        left: 258px;
        top: 1835px;
        border-radius: 0px;
    }
    .sect {
        height: 394px;
        width: 95vw;
        left: 40px;
        top: 1719px;
        border-radius: 0px;
        margin-top: 37px;
        background-color: #fdfdfd;
        padding-left: 84px;
        padding-right: 100px;
        padding-top: 30px;
    }
    .slick-prev:before {
        color: black !important;
        background-color: #eee;
    }
    .slick-next:before {
        color: black !important;
        background-color: #eee;
    }
    .swiper-button-next,
    .swiper-button-prev {
        width: 72px !important;
        height: 71px !important;
        background: rgba(46, 45, 45, 0.65) !important;
        border-radius: 10px !important;
    }
    .swiper-button-next::after,
    .swiper-button-prev::after {
        color: white !important;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 13px !important;
        right: auto !important;
    }
    .desc {
        padding-left: 60px;
    }
    .buynowmodal {
        /* height: 110vh;
        width: 781px; */
        border-radius: 18px;
        background-color: #FFFFFF;
    }
    #sidebar {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 400px;
        background: #FFFFFFF7;
        z-index: 9000;
        transition: all 0.5s ease;
    }
    .open {
        width: 75%;
    }
    .close {
        display: none;
    }
    .sidebar_header {
        height: 143px;
        width: 100%;
        background-color: rgb(61, 155, 92);
    }
    .sidebar_header .con {
        padding-left: 16px;
        padding-top: 20px;
        display: flex;
        align-items: center;
    }
    .holder {
        height: 80px;
        width: 80px;
        background-color: #fafafa;
        border-radius: 100%;
        margin-right: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebar_name {
        right: 5.48%;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
        color: #fafafa;
    }
    .sidebar_email {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 10px;
        color: #fafafa;
    }
    #checkout_img {
        height: 208px;
        width: 206px;
        margin-bottom: 32px;
    }
    #checkout_img_container {
        height: 350px;
        width: 387px;
        border-radius: 18px;
        background-color: #FAFAFA;
        padding-top: 46px;
    }
    .levels {
        border-bottom: 2px dotted #cacaca;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .level_indicator {
        display: flex;
        align-items: center;
    }
    .level_counter {
        height: 30px;
        width: 30px;
        background-color: rgb(18, 150, 77);
        color: #fafafa;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .address_con {
        width: 100%;
        height: 319px;
        background: #FFFFFF;
        border-radius: 12px;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .small_con {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #f0f0f0;
        width: 46px;
        height: 46px;
        border-radius: 5px;
    }
    #checkout_tag {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
    }
}

@media (max-width: 760px) {
    .cardx {
        width: 110px;
        height: 90px;
    }
    .cat-img {
        width: 60px;
        height: 50px;
    }
    .bg-holder {
        position: relative;
        width: 100%;
        height: fit-content;
        background-color: #ccc;
    }
    .swiper {
        width: 100%;
        height: 240px;
        z-index: 3;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 240px;
        object-fit: fill;
    }
    #sectioner {
        position: absolute;
        left: 0;
        top: 200px;
        z-index: 30;
        width: 100%;
    }
    #sectioner #secondcat {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;
    }
    .slide {
        height: 300px;
        width: 100%;
        top: 1719px;
        border-radius: 0px;
        background-color: #fdfdfd;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 30px;
    }
    #apex {
        margin-top: 175vh;
    }
    .slide_img {
        height: 150px;
        width: 46%;
        left: 258px;
        top: 1835px;
        border-radius: 0px;
    }
    .sect {
        height: 394px;
        width: 95vw;
        left: 40px;
        top: 1719px;
        border-radius: 0px;
        margin-top: 37px;
        background-color: #fdfdfd;
        padding-left: 84px;
        padding-right: 100px;
        padding-top: 30px;
    }
    .slick-prev:before {
        color: black !important;
        background-color: #eee;
    }
    .slick-next:before {
        color: black !important;
        background-color: #eee;
    }
    .swiper-button-next,
    .swiper-button-prev {
        width: 67px !important;
        height: 67px !important;
        background: rgba(46, 45, 45, 0.65) !important;
        border-radius: 10px !important;
        padding: 5px;
    }
    .swiper-button-next::after,
    .swiper-button-prev::after {
        color: white !important;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 13px !important;
        right: auto !important;
    }
    .desc {
        padding: 0;
    }
    #sidebar {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        background: #FFFFFFF7;
        z-index: 9000;
        transition: all 0.5s ease;
    }
    .open {
        width: 75%;
    }
    .close {
        display: none;
    }
    .sidebar_header {
        height: 117px;
        width: 100%;
        background-color: rgb(61, 155, 92);
        border-bottom: 1px solid #ccc
    }
    .sidebar_header .con {
        padding-left: 16px;
        padding-top: 18px;
        display: flex;
        align-items: center;
    }
    .holder {
        height: auto;
        width: 68px;
        background-color: #ccc;
        border-radius: 100%;
        margin-right: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebar_name {
        right: 5.48%;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 7px;
        color: #000000;
    }
    .sidebar_email {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 10px;
        color: #000000;
    }
    #checkout_img_container {
        height: 350px;
        width: 100%;
        border-radius: 18px;
        background-color: #FAFAFA;
        padding-top: 46px;
        margin: 0;
    }
    #checkout_img {
        height: 208px;
        width: 206px;
        margin-bottom: 20px;
    }
    .levels {
        border-bottom: 2px dotted #cacaca;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .level_indicator {
        display: flex;
        align-items: center;
    }
    .level_counter {
        height: 30px;
        width: 30px;
        background-color: rgb(18, 150, 77);
        color: #fafafa;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .address_con {
        width: 100%;
        height: 319px;
        background: #FFFFFF;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .small_con {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #f8f8f8;
        width: 46px;
        height: 46px;
    }
    #checkout_tag {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
    }
}

@media (max-width: 576px) {
    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 18px !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
        width: 35px !important;
        height: 35px !important;
    }
}

@media (max-width: 768px) {
    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 25px !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
        width: 50px !important;
        height: 50px !important;
    }
}

.more_images_container {
    width: 100%;
}

.writeup_holder {
    padding-top: 130px;
}

#product_title {
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
}

#product_desc {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    text-align: left;
    margin-top: 25px;
    margin-bottom: 30px;
}

.price_container {
    height: 49px;
    width: fit-content;
    border-radius: 6px;
    padding: 10px;
    background-color: #fafafa;
}

.ellipse {
    height: 473px;
    width: 463px;
    left: 51px;
    top: 0px;
    margin-top: 30px;
    border-radius: 1000px;
    position: absolute;
    background-color: rgba(24, 155, 98, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
}

#shopper {
    height: 412.0602722167969px;
    width: 564px;
    left: 0px;
    top: 51px;
    border-radius: 0px;
}

#settings_title {
    font-family: poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: #626262;
}

#profile_title {
    font-family: poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    color: #262525;
}

.texts {
    font-family: poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2C2C2C;
}

.sidebar_body h1 strong {
    font-size: 17px;
}

.sidebar_body li {
    margin-top: 17px;
    font-size: 13px;
    font-weight: 800;
}

#welcome {
    width: 399px;
    height: 58px;
    left: 109px;
    top: 238px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 58px;
}

.outline_btn {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    color: green;
    border: 2.5px solid #189B62;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 8px;
}

.outline_btn:hover {
    background-color: #04AA6D;
    color: white;
}

#selected {
    border: 2px solid rgb(255, 255, 0);
    background-color: whitesmoke;
    color: black;
    border-radius: 100px;
    margin-left: 5px;
}

.list {
    width: 173px;
    height: 19px;
    font-family: poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.logout_sidebar {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    padding: 4px;
    border-radius: 7px;
    font-weight: bold;
    width: 100px;
}

.logout_sidebar.red {
    background: #F3E1E1;
}

.logout_sidebar.green {
    background: #e1f3e4;
}

.logout_sidebar.yellow {
    background: #f3f3e1;
}