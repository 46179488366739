@media(min-width:760px) {
    .cards {
        width: 100%;
        height: 100px;
        background: rgba(194, 194, 194, 0.17);
        border-radius: 12px;
        display: flex;
        align-items: center;
    }
    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 60px;
        height: 60px;
        background: rgba(94, 182, 236, 0.19);
        border-radius: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: rgba(8, 49, 31, 0.67);
    }
    .sub_title {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */
        color: rgba(18, 48, 38, 0.81);
    }
}

@media(max-width:760px) {
    .cards {
        width: 100%;
        height: 80px;
        background: rgba(194, 194, 194, 0.17);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        width: 40px;
        height: 40px;
        background: rgba(94, 182, 236, 0.19);
        border-radius: 50px;
    }
    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(8, 49, 31, 0.67);
    }
    .sub_title {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */
        color: rgba(18, 48, 38, 0.81);
    }
}