.skeleton {
    opacity: .7;
    animation: skeleton-loading 1s ease-out infinite alternate;
}

.skeleton.skeleton-text {
    width: 100%;
    height: .5em;
    margin-bottom: .25rem;
    border-radius: .125rem;
}

.skeleton.skeleton-title {
    width: 100%;
    height: 1em;
    margin-bottom: .25rem;
    border-radius: .125rem;
}

.skeleton.skeleton-text:last-child {
    width: 80%;
    margin-bottom: 0;
}

@keyframes skeleton-loading {
    0% {
        background-color: #ffffff;
    }
    50% {
        background-color: #e9e5e5;
    }
    100% {
        background-color: #dcdcdc;
    }
}


/* basic styles */


/* .skeleton {
    background: #ddd;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 4px;
}

.skeleton.text {
    width: 100%;
    height: 12px;
}

.skeleton.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 100px;
    height: 100px;
} */


/* .skeleton-wrapper {
    margin: 20px auto;
    padding: 10px 15px; */


/* add border radius to soften wrapper with theme */


/* border-radius: 4px;
} */


/* skeleton profile */


/* .skeleton-profile {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
    align-items: center;
} */


/* themes */


/* .skeleton-wrapper.light {
    background: #f2f2f2;
}

.skeleton-wrapper.dark {
    background: #444;
}

.skeleton-wrapper.dark .skeleton {
    background: #777;
} */